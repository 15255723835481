<template>
    <div class="header_common">
        <div class="logo_wrapper">
            <img src="../../assets/Bear.png" alt="logo" class="icon">
            <img src="../../assets/name.svg" alt="name" class="name">
        </div>
        <div class="right">
            <img v-show="navOpen" src="../../assets/nav_close.png" @click="navOpen = !navOpen" alt="more" class="nav_iocn">
            <img v-show="!navOpen" src="../../assets/nav_more.png" @click="navOpen = !navOpen" alt="more" class="nav_iocn">
            <nav>
                <ul class="link_list" v-show="navOpen">
                    <li v-for="link in links" :key="link.name" @click="open(link)">{{link.name}}</li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import { links, debounce } from "@/utils.js";
export default {
    data() {
        return {
            navOpen: false,
            links,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll: debounce(function () {
            this.navOpen = false;
        }, 1000, true),
        open(link) {
            this.navOpen = false;
            if (link.path.startsWith("http")) {
                window.open(link.path);
                return;
            } 
            if (link.path === "/buahKita") {
                this.$router.push({ path: "/buahKita" });
                return;
            }
            if (this.$route.path === "/") {
                let split = link.path.split("#");
                if (split.length && split[0] === "/" && split[1]) {
                    document.querySelector("#" + split[1]).scrollIntoView();
                }
                return
            } else {
                this.$router.push({ path: link.path });
            }
            
        },
    }
};
</script>

<style lang="scss" scoped>
.header_common {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.15rem;
    position: sticky;
    box-shadow: 0px 3px 6px 0px #0000000A;
    img {
        vertical-align: middle;
    }
    .logo_wrapper {
        display: flex;
        align-items: center;
        height: 100%;
        .icon {
            width: 2.5rem;
            height: 2.5rem;
            margin: 0 0.7rem;
        }
        .name {
            width: 6rem;
            height: 2.5rem;
        }
    }
    .right {
        margin-right: 0.7rem;
        position: relative;
        .nav_iocn {
            width: 1.6rem;
            height: 1.6rem;
        }
        .link_list {
            position: absolute;
            right: 0;
            top: calc(100% + 0.3rem);
            z-index: 1;
            background: #fff;
            list-style: none;
            border-radius: 0.3rem;
            width: 12rem;
            overflow: hidden;
            box-shadow: 0px 0px 0.7rem #00000033;
            li {
                padding: 0.6rem 2rem 0.6rem 1rem;
                width: 100%;
                border-bottom: 1px solid #efefef;
                color: #02283f;
                font-weight: 500;
                a {
                    text-decoration: none;
                }
                &:nth-last-child(1) {
                    border-bottom: none;
                }
            }
            li:hover {
                background: #0060ff;
                color: #fff;
            }
        }
    }
}
</style>
